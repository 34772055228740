<template>
  <a-card :bodyStyle="{ padding: 0 }" :bordered="false">
    <template #title>
      <a-form :model="form" layout="inline">
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.id" placeholder="流水编号" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.name" placeholder="名称" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.objectName" placeholder="收支对象" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.revenueAndExpenditureType" placeholder="收支类型" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input-number v-model:value="form.moneyStart" style="width: 100%; text-align: center" placeholder="最小金额" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input-number v-model:value="form.moneyEnd" style="width: 100%; text-align: center; border-left: 0"
            placeholder="最大金额" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.accountName" placeholder="收支账户" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.summary" placeholder="收支摘要" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.officeOrgName" placeholder="部门" />
        </a-form-item>
        <a-form-item style="width: 27%">
          <a-range-picker v-model:value="form.createTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :showTime="false"
            style="width:100%" :placeholder="['创建日期起', '创建日期至']" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-space>
            <a-button type="primary" @click="search">查询</a-button>
            <a-button @click="resetSearch">重置</a-button>
            <!-- <a-button type="link" @click="open = !open">{{ open ? '收起' : '展开' }}
              <UpOutlined v-if="open" />
              <DownOutlined v-else />
            </a-button> -->
            <a-button type="dashed" @click="exportData" :disabled="exportDisabled">导出Excel</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </template>
    <a-table :dataSource="dataSource" :rowKey="record => record.id" :loading="loading" :pagination="pagination" bordered
      size="small" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <a-table-column title="流水编号" data-index="id" />
      <a-table-column title="名称" data-index="name" />
      <a-table-column title="收支对象" data-index="objectName" />
      <a-table-column title="对象类型" data-index="type" />
      <a-table-column title="收支类型" data-index="revenueAndExpenditureType" />
      <a-table-column title="金额（元）" data-index="money" />
      <a-table-column title="收支账户" data-index="accountName" />
      <a-table-column title="出纳" data-index="cashier" />
      <a-table-column title="区域归属" data-index="officeOrgName" />
      <a-table-column title="摘要" data-index="summary" />
      <a-table-column title="备注" data-index="remark" />
      <a-table-column title="发起人" data-index="sponsor" />
      <a-table-column title="创建时间" data-index="createTime" />
    </a-table>
  </a-card>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import { runningExport, runningPage } from '@/api/trans/finance/account'
import { getOrgList } from '@/utils/util'

import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { Form } from 'ant-design-vue'
// import { list as orgList } from '@/api/system/organzition'
import { UpOutlined, DownOutlined } from '@ant-design/icons-vue'
export default {
  setup () {
    const store = useStore()
    const form = reactive({
      id: '',
      name: '',
      objectName: '',
      revenueAndExpenditureType: '',
      moneyStart: '',
      moneyEnd: '',
      accountName: '',
      summary: '',
      officeOrgName: '',
      createTime: []
    })

    const state = reactive({
      exportDisabled: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadData()
        }
      },

      open: false,
      loading: false,
      orgs: [],
      dataSource: [
      ],
      detailsLength: 0,
      orgCheckedAll: true,
      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ]
    })
    const useForm = Form.useForm
    const { resetFields } = useForm(form, ref({}))

    const exportData = () => {
      state.exportDisabled = true
      runningExport({
        ...form,
        startTime: form.createTime[0],
        endTime: form.createTime[1]
      }, '财务-分流明细').then(res => {
        setTimeout(function () {
          state.exportDisabled = false
        }, 10000)
      })
    }

    const loadData = () => {
      state.loading = true
      runningPage({
        ...form,
        startTime: form.createTime[0],
        endTime: form.createTime[1],
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }

    onMounted(async () => {
      state.orgs = store.state.app.orgTypeList
      if (state.orgs === [] || state.orgs === null || state.orgs === undefined || state.orgs.length <= 0) {
        state.orgs = await getOrgList(1)
        // orgList({ orgType: 1 }).then(res => {
        //   state.orgs = res.data
        // })
      }
      loadData()
    })

    const search = () => {
      state.pagination.current = 1
      loadData()
    }

    const resetSearch = () => {
      resetFields()
      search()
    }

    return {
      form,
      ...toRefs(state),
      search,
      resetSearch,
      loadData,
      exportData
    }
  },
  components: {
    UpOutlined,
    DownOutlined
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-form-item){
  margin-bottom:10px;
}
</style>
