<template>
  <a-card :bodyStyle="{padding:0}">
    <a-tabs v-model:activeKey="activeKey" @change="tabsChange">
      <a-tab-pane :key="1" tab="流水">
        <iSummary />
      </a-tab-pane>
      <a-tab-pane :key="2" tab="分流">
        <detail />
      </a-tab-pane>
      <a-tab-pane :key="3" tab="余额">
         <balance ref="balanceRef"/>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>
<script>
import { reactive, toRefs,ref } from 'vue'
import detail from './detail.vue'
import iSummary from './iSummary.vue'
import balance from './balance.vue'
export default {
  setup () {
    const balanceRef = ref(null)
    const state = reactive({
      activeKey: 1
    })
    const tabsChange = (e) => {
      if(state.activeKey === 3) {
        setTimeout(()=>{
          balanceRef.value.loadData()
        })
      }
    }
    return {
      ...toRefs(state),
      balanceRef,
      tabsChange
    }
  },
  components: {
    detail,
    iSummary,
    balance
  }
}
</script>
