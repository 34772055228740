<template>
  <a-card :bodyStyle="{ padding: 0 }" :bordered="false">
    <template #title>
      <a-form :model="form" layout="inline">
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.uniqueNumber" placeholder="识别码" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.accountNumber" placeholder="流水编号" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input-number v-model:value="form.tradeAmtStart" style="width: 100%; text-align: center"
            placeholder="交易最小金额" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input-number v-model:value="form.tradeAmtEnd"
            style="width: 100%; text-align: center; border-left: 0" placeholder="交易最大金额" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.weAccount" placeholder="交易账户" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.otherAccount" placeholder="对方账户" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.otherName" placeholder="对方名称" />
        </a-form-item>
        <a-form-item style="width: 27%">
          <a-range-picker v-model:value="form.tradeTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :showTime="false"
            style="width:100%" :placeholder="['交易日期起', '交易日期止']" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-select v-model:value="form.confirm" style="width:100%" placeholder="是否分流">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-select v-model:value="form.isSystem" style="width:100%" placeholder="是否系统流水">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="width: 27%">
          <a-space>
            <a-button type="primary" @click="search">查询</a-button>
            <a-button @click="resetSearch">重置</a-button>
            <!-- <a-button type="link" @click="open = !open">{{ open ? '收起' : '展开' }}
              <UpOutlined v-if="open" />
              <DownOutlined v-else />
            </a-button> -->
            <a-button type="dashed" @click="uploadVisible = true">上传文件</a-button>
            <a-button type="dashed" @click="exportData" :disabled="exportDisabled">导出Excel</a-button>
            <a-button @click="accountsVisible = true" danger>添加账号</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </template>
    <a-table :dataSource="dataSource" :rowKey="record => record.id" :loading="loading" :pagination="pagination" bordered
      size="small" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <a-table-column :width="80" title="识别码" data-index="uniqueNumber" />
      <a-table-column title="流水编号" data-index="accountNumber" />
      <a-table-column title="交易日期" data-index="tradeTime" />
      <a-table-column title="交易类别" data-index="tradeTypeName" />
      <a-table-column title="交易金额" data-index="tradeAmt" />
      <a-table-column title="交易用途" data-index="tradePurpose" />
      <a-table-column title="我方账户" data-index="weAccount" />
      <a-table-column title="对方账户" data-index="otherAccount" />
      <a-table-column title="对方名称" data-index="otherName" />
      <a-table-column title="对方开户行" data-index="otherBank" />
      <a-table-column title="确认状态" data-index="isConfirm.label" />
      <a-table-column title="操作">
        <template #default="{ record }">
          <a-popconfirm title="确认删除？" ok-text="确认" cancel-text="取消" @confirm="deleteRecord(record)">
            <a href="#">删除分流</a>
          </a-popconfirm>
          <a-button type="link" v-if="!record.isConfirm.value" @click="splitDetails(record)">拆分</a-button>
          <a-button type="link" v-if="record.isConfirm.value" @click="check(record.id)">查看</a-button>
          <a-button type="link" v-if="!record.isConfirm.value" @click="generateDetails(record)">生成明细</a-button>
        </template>
      </a-table-column>
    </a-table>
  </a-card>
  <a-modal v-model:visible="checkVisible" title="查看" :footer="false" :width="1400">
    <a-table :dataSource="checkDataSource" :rowKey="record => record.id" :loading="checkLoading"
      :pagination="checkPagination" bordered size="small"
      :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <a-table-column title="流水编号" data-index="id" />
      <a-table-column title="名称" data-index="name" />
      <a-table-column title="收支对象" data-index="objectName" />
      <a-table-column title="对象类型" data-index="type" />
      <a-table-column title="收支类型" data-index="revenueAndExpenditureType" />
      <a-table-column title="金额（元）" data-index="money" />
      <a-table-column title="收支账户" data-index="accountName" />
      <a-table-column title="出纳" data-index="cashier" />
      <a-table-column title="区域归属" data-index="officeOrgName" />
      <a-table-column title="摘要" data-index="summary" />
      <a-table-column title="备注" data-index="remark" />
      <a-table-column title="发起人" data-index="sponsor" />
      <a-table-column title="创建时间" data-index="createTime" />
    </a-table>
  </a-modal>
  <a-modal v-model:visible="accountsVisible" title="添加账号" :confirmLoading="accountsLoading" @ok="addAccounts"
    @cancel="accountsForm = {}" :maskClosable="false" destroyOnClose>
    <a-form ref="accountsFormRef" :model="accountsForm" :rules="rules" :labelCol="{ flex: '100px' }">
      <a-form-item label="账户性质" name='accountType'>
        <a-radio-group v-model:value="accountsForm.accountType">
          <a-radio-button :value="1">公账</a-radio-button>
          <a-radio-button :value="2">私账</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="单位名称" name='unitName'>
        <a-input v-model:value="accountsForm.unitName" autocomplete="off" />
      </a-form-item>
      <a-form-item label="户名" name='accountName'>
        <a-input v-model:value="accountsForm.accountName" autocomplete="off" />
      </a-form-item>
      <a-form-item label="银行">
        <a-input v-model:value="accountsForm.bankName" autocomplete="off" />
      </a-form-item>
      <a-form-item label="账号" name='cardNo'>
        <a-input v-model:value="accountsForm.cardNo" autocomplete="off" />
      </a-form-item>
      <a-form-item label="总余额" name='totalAmt'>
        <a-input-number v-model:value="accountsForm.totalAmt" :min="0" style="width:100%" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="detailsVisible" title="生成明细" :confirmLoading="detailsLoading"
    :ok-button-props="driverdisabled" @ok="generateDetailsCreate" width="80%">
    <h1 class='deriver_modeal_h1'>交易总金额：<span>{{ total }}</span></h1>
    <a-button class="editable-add-btn" @click="handleAdd" style="margin-bottom: 8px" type="primary" danger>新增</a-button>
    <a-table bordered :data-source="dataSourceDetail" :columns="columnsDetail" size='small' :pagination='false'
      :rowKey="(record, index) => { return index }">
      <template #name="{ record }">
        <a-input v-model:value="record.name" />
      </template>
      <template #officeOrgName="{ record }">
        <a-select ref="select" v-model:value="record.officeOrgId" style="width:260px"
          @change="officeOrgNameAssignment(record, 2)">
          <a-select-option v-for="item in orgList " :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </template>
      <template #money="{ record }">
        <a-input v-model:value="record.money" min='1' type='number' />
      </template>
      <template #summary="{ record }">
        <a-textarea v-model:value="record.summary" :maxlength="250" showCount />
      </template>
      <template #remark="{ record }">
        <a-input v-model:value="record.remark" />
      </template>
      <template #operation="{ record }">
        <a-popconfirm v-if="dataSourceDetail.length" title="&nbsp&nbsp确认删除吗?&nbsp&nbsp" @confirm="onDelete(record.key)">
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="splitVisible" title="拆分明细" :confirmLoading="detailsLoading" :ok-button-props="driverdisabled"
    @ok="generateSplit" width="80%">
    <h1 class='deriver_modeal_h1'>交易总金额：<span>{{ total }}</span></h1>
    <a-button class="editable-add-btn" @click="handleAdd" style="margin-bottom: 8px" type="primary" danger>新增</a-button>
    <a-table bordered :data-source="dataSourceDetail" :columns="columnsSplit" size='small' :pagination='false'
      :rowKey="(record, index) => { return index }">
      <template #money="{ record }">
        <a-input v-model:value="record.money" min='1' type='number' />
      </template>
      <template #operation="{ record }">
        <a-popconfirm v-if="dataSourceDetail.length" title="&nbsp&nbsp确认删除吗?&nbsp&nbsp" @confirm="onDelete(record.key)">
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="uploadVisible" title="文件上传" :confirmLoading="upLoading" @ok="handleUpload"
    @cancel="fileList = []" :maskClosable="false" destroyOnClose>
    <a-upload :before-upload="beforeUpload" class="upload-list-inline" v-model:file-list="fileList" accept=".xlsx">
      <a-button>
        选择文件
      </a-button>
    </a-upload>
  </a-modal>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import { intercoursePage, batchIntercourseFile, intercourseCreate, accountAdd, deleteData, runningDetail, intercourseExport, intercourseSplit } from '@/api/trans/finance/account'
import { message, Form } from 'ant-design-vue'
import { carUpload } from '@/api/transport/transport'
import { getOrgList } from '@/api/global'
import { onMounted } from '@vue/runtime-core'
import { UpOutlined, DownOutlined } from '@ant-design/icons-vue'
import { watch } from 'vue'
export default {
  setup () {
    const form = reactive({
      uniqueNumber: '',
      accountNumber: '',
      tradeAmtStart: '',
      tradeAmtEnd: '',
      weAccount: '',
      tradeTime: [],
      otherAccount:'',
      otherName:'',
      confirm: null,
      isSystem:null
    })

    const state = reactive({
      exportDisabled: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadData()
        }
      },
      checkVisible: false,
      // 账户
      accountsFormRef: null,
      accountsVisible: false,
      accountsLoading: false,
      accountsForm: {},
      // 上传
      uploadVisible: false,
      upLoading: false,

      // 明细
      total: 0,
      totalid: '',
      detailsVisible: false,
      splitVisible: false,
      detailsLoading: false,
      dataSourceDetail: [],
      checkLoading: false,
      orgList: [],
      checkPagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10','30','60','80'],
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.checkPagination.current = page
          state.checkPagination.pageSize = pageSize
          checkData()
        }
      },
      driverdisabled: { disabled: true },
      fileList: [],
      open: false,
      loading: false,

      dataSource: [],
      checkDataSource: [],
      columnsDetail: [
        {
          title: '费用类别',
          dataIndex: 'name',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: '部门/同行',
          dataIndex: 'officeOrgName',
          slots: {
            customRender: 'officeOrgName'
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          slots: {
            customRender: 'money'
          }
        },
        {
          title: '摘要',
          dataIndex: 'summary',
          slots: {
            customRender: 'summary'
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          slots: {
            customRender: 'remark'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      columnsSplit: [
        {
          title: '金额',
          dataIndex: 'money',
          slots: {
            customRender: 'money'
          }
        }
      ],
      detailsLength: 0,
      orgCheckedAll: true,
      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ],
      rules: {
        accountType: { required: true, message: '请选择账户性质', trigger: 'change', type: 'number' },
        unitName: { required: true, message: '请填写单位名称', trigger: 'blur' },
        accountName: { required: true, message: '请填写户名', trigger: 'blur' },
        totalAmt: { required: true, message: '请填写总余额', trigger: 'blur', type: 'number' },
        cardNo: { required: true, message: '请填写账号', trigger: 'blur' }
      }
    })

    const useForm = Form.useForm
    const { resetFields } = useForm(form, ref({}))

    const exportData = () => {
      state.exportDisabled = true
      intercourseExport({
        ...form,
        tradeTimeStart: form.tradeTime[0],
        tradeTimeEnd: form.tradeTime[1]
      }, '财务-往来明细').then(res => {
        setTimeout(function () {
          state.exportDisabled = false
        }, 10000)
      })
    }
    const check = (id) => {
      state.checkVisible = true
      checkData(id)
    }
    const checkData = (id) => {
      state.checkLoading = true
      runningDetail(id).then(res => {
        if (res.code === 10000) {
          state.checkDataSource = res.data
        }
      }).finally(() => {
        state.checkLoading = false
      })
    }
    const deleteRecord = (record) => {
      deleteData(record.id).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        } else {
          message.error(res.msg)
        }
      })
    }
    const generateDetails = (record) => {
      state.total = record.tradeAmt
      state.totalid = record.id
      state.detailsVisible = true
    }

    const splitDetails = (record) => {
      state.total = record.tradeAmt
      state.totalid = record.id
      state.splitVisible = true
    }

    const generateDetailsCreate = (record) => {
      state.detailsLoading = true
      intercourseCreate({
        accounts: state.dataSourceDetail,
        intercourseId: state.totalid
      }).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
          state.dataSourceDetail = []
          state.detailsVisible = false
          state.driverdisabled.disabled = true
          state.total = 0
          loadData()
        }
      }).finally(() => {
        state.detailsLoading = false
      })
    }

    const generateSplit = (record) => {
      state.detailsLoading = true
      intercourseSplit({
        accounts: state.dataSourceDetail,
        intercourseId: state.totalid
      }).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
          state.dataSourceDetail = []
          state.detailsVisible = false
          state.driverdisabled.disabled = true
          state.total = 0
        }
      }).finally(() => {
        state.detailsLoading = false
      })
    }

    const loadData = () => {
      state.loading = true
      intercoursePage({
        ...form,
        tradeTimeStart: form.tradeTime[0],
        tradeTimeEnd: form.tradeTime[1],
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }

    onMounted(() => {
      loadData()
      getOrgList({}).then(res => {
        state.orgList = res.data
      })
    })

    const search = () => {
      state.pagination.current = 1
      loadData()
    }

    const resetSearch = () => {
      resetFields()
      search()
    }

    const beforeUpload = file => {
      state.fileList.length = 0
      state.fileList = [...state.fileList, file]
      return false
    }

    const handleUpload = () => {
      state.upLoading = true
      const formData = new FormData()
      state.fileList.forEach(file => {
        formData.append('file', file.originFileObj)
      })
      carUpload(formData).then((res) => {
        if (res.code === 10000) {
          state.fileList = []
          state.uploadVisible = false
          batchIntercourseFile(res.data.id, '').then((res) => {
            message.info(res.msg)
            loadData()
          })
        } else {
          message.error(res.msg)
        }
        state.upLoading = false
      })
    }

    const onDelete = key => {
      state.dataSourceDetail = state.dataSourceDetail.filter(item => item.key !== key)
    }

    const handleAdd = () => {
      const newData = {
        key: `${state.dataSourceDetail.length + 1}`,
        name: '',
        money: 1,
        officeOrgName: '',
        remark: '',
        summary: ''
      }
      state.dataSourceDetail.push(newData)
    }

    watch(() => state.dataSourceDetail, (dataSourceDetail) => {
      let totalCompare = 0
      state.dataSourceDetail.forEach(item => {
        totalCompare += Number(item.money) * 1000
      })
      state.driverdisabled.disabled = (Number(state.total) * 1000).toFixed() !== Number(totalCompare).toFixed()
    }, { deep: true })

    const officeOrgNameAssignment = (record, item) => {
      switch (item) {
        case 2:
          for (let i = 0; i < state.orgList.length; i++) {
            if (state.orgList[i].id === record.officeOrgId) {
              record.officeOrgName = state.orgList[i].name
              return
            }
          }
          break
        default:
          message.success('出现错误' + item)
      }
    }

    const addAccounts = () => {
      state.accountsFormRef.validate().then(() => {
        state.accountsLoading = true
        accountAdd(state.accountsForm).then((res) => {
          if (res.code === 10000) {
            message.info(res.msg)
            state.accountsForm = {}
            state.accountsVisible = false
          }
        }).finally(() => {
          state.accountsLoading = false
        })
      })
    }

    return {
      form,
      ...toRefs(state),
      search,
      resetSearch,
      officeOrgNameAssignment,
      handleAdd,
      beforeUpload,
      loadData,
      exportData,
      generateDetailsCreate,
      generateSplit,
      handleUpload,
      onDelete,
      generateDetails,
      addAccounts,
      deleteRecord,
      check,
      checkData,
      splitDetails
    }
  },
  components: {
    UpOutlined,
    DownOutlined
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-form-item){
  margin-bottom:10px;
}
.deriver_modeal_h1 {
  text-align: center;
  padding-bottom: 15px;

  span {
    color: red;
  }
}
</style>
