<template>
  <a-table :dataSource="dataSource" :pagination="pagination" :rowKey="record => record.id" :loading="loading" bordered size="small" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
    <a-table-column :width="200" title="单位名称" data-index="unitName" />
    <a-table-column :width="120" title="户名" data-index="accountName" />
    <a-table-column :width="120" title="开户行" data-index="bankName" />
    <a-table-column :width="100" title="账户性质" data-index="accountType.label" />
    <a-table-column :width="100" title="账户卡号" data-index="cardNo" />
    <a-table-column :width="100" title="期初余额" data-index="totalAmt" />
    <a-table-column :width="100" title="当前余额" data-index="nowAmt" />
    <a-table-column :width="100" title="15点存档额" data-index="filingAmt" />
  </a-table>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { accountPage } from '@/api/trans/finance/account'
import { onMounted } from '@vue/runtime-core'
export default {
  setup () {
    const data = reactive({
      dataSource: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          data.pagination.current = page
          data.pagination.pageSize = pageSize
          loadData()
        }
      }
    })

    const loadData = () => {
      data.loading = true
      accountPage({ current: data.pagination.current, size: data.pagination.pageSize }).then(res => {
        if (res.code === 10000) {
          data.dataSource = res.data.records
          data.pagination.current = res.data.current
          data.pagination.pageSize = res.data.size
          data.pagination.total = res.data.total
        }
      }).finally(() => {
        data.loading = false
      })
    }

    return {
      ...toRefs(data),
      loadData
    }
  }
}
</script>
